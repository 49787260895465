/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State, Action} from 'vuex-class'
import { ModelAccountState } from '@/store/modelAccount/types'

const namespace: string = 'modelAccount';


export default class ModelAccountMyAccountDmcaPage extends Vue {
	@State('modelAccount', {namespace}) modelAccountState: ModelAccountState | undefined;
	@Action('updateModelPersonal', {namespace}) updateModelPersonal: any;
	@Action('getModelPersonal', {namespace}) getModelPersonal: any;
	@Getter('getModelLogin', {namespace}) getModelLogin: any;
	@Getter('getModelDCMAProtect', {namespace}) getModelDCMAProtect: any;
	@Mutation('setModelDCMAProtect', {namespace}) setModelDCMAProtect: any;

	dcmaProtect: boolean = false;

	async setUpdate() {
		this.setModelDCMAProtect(this.dcmaProtect);
		await this.updateModelPersonal({
			dcmaProtect: this.dcmaProtect
		});
	}

	mounted() {
		this.getModelPersonal();
		this.dcmaProtect = this.getModelDCMAProtect;
	}
}
